import React, { useState, useContext, useRef, useEffect } from 'react'
import { AppContext, AppProvider } from "./AppContext"
import { makeStyles } from "@material-ui/core"
import { RiPixelfedLine, RiEnglishInput } from "react-icons/ri"
import { FaBars, FaLanguage } from "react-icons/fa"
import logo from "./images/logo.png"
// import { IoLanguageOutline } from "react-icons/io"

const useStyles = makeStyles(theme => ({
    navGroup: {
        position: "fixed",
        top: "0",
        left: "0",
        right: "0",
        display: "flex",
        justifyContent: "space-between",
        backgroundSize: "cover",
        backgroundPosition: "center",
        background: 'white',
        // boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.12), 0 5px 5px rgba(0, 0, 0, 0.24)",
        fontSize: "1.1rem",
        // color: "rgb(79,22,9)",
        zIndex: 1000,

    },
    homeGroup: {
        padding: ".5rem 1rem",
        fontFamily: "Indie Flower, cursive",
        fontSize: "1.5rem",
        cursor: "pointer"
    },
    homeIcon: {
        display: "inline-block",
        verticalAlign: "middle",
        color: "rgb(4,170,109)",
        height: "30px",
        width: "30px",
    },
    homeImg: {
        width: "100%",
        objectFit: "cover",
        objectPosition: "top",
    },
    jump: {
        paddingLeft: ".5rem",
    },
    pixels: {
        color: "rgb(126,174,174)",
        fontWeight: "bold",
    },
    others: {
        display: "flex",
        alignSelf: "center",
    },
    singleNav: {
        padding: "0 1rem",
        cursor: "pointer",
        // "&:hover": isTouchEnabled() ? {} : {
        //         color: "rgb(145,15,15)",
        //     }
    },
    highlight: {
        color: "rgb(126,174,174)",
        fontWeight: "bold"
    },
    language: {
        alignSelf: "center",
        padding: "0 1rem",
        cursor: "pointer"
    },
    highlightForLanguage: {
        background: "rgb(126,174,174)",
        color: "white"
    },

    "@media screen and (max-width: 700px)": {
        navGroup: {
            fontSize: "1rem",
        },
        homeGroup: {
            padding: "0 .5rem",
        },
        singleNav: {
            padding: "0.2rem .5rem",
        },
        smallForOthers: {
            position: "relative",
            display: "flex",
        },
        iconNav: {
            paddingRight: "1rem",
            verticalAlign: "middle",
            alignSelf: "center",
            // paddingRight: "1rem",
            cursor: "pointer",
            zIndex: 10
        },
        singleNavGroup: {
            position: "absolute",
            top: "100%",
            height: "100px",
            width: "120px",
            right: "0",
            background: "white",
            border: "1px solid lightgrey"
        },

        noNavGroup: {
            display: "none",
            // right:"-50%"
        },
        singleNav: {
            padding: "0.4rem 1rem",
        },
        language: {
            padding: "0 .5rem",
        }
    },
}));

const languageArr = [["En", "CN"], ["EN", "中文"]]
const navArr = [["JumpPixels", "Websites", "Apps"], ["JumpPixels", "网站", "应用程序"]]


const Nav = ({ rootRef }) => {
    const [state, setState] = useContext(AppContext)
    const [navIndex, setNavIndex] = useState(0)
    const [showNav, setShowNav] = useState(false)
    const trackNavRef = useRef(null)
    const classes = useStyles();


    useEffect(() => {
        let interval = setInterval(() => {
            if (trackNavRef.current) {
                setState((s) => {
                    return ({
                        ...s,
                        navTopHeight: trackNavRef.current.offsetHeight,
                    })
                })
                clearInterval(interval)
            }
        }, 100)

    }, [])


    const loadListener = () => {

        setState((c) => {
            return ({
                ...c,
                windowWidth: window.innerWidth
            })
        })
    }
    useEffect(() => {
        loadListener();
        window.addEventListener("resize", loadListener)
        return (() => {
            window.addEventListener("resize", loadListener)
        }
        )
    }, [])

    const handleHomeNavClick = () => {
        setState((c) => {
            return ({
                ...c,
                currentDisplayIndex: 0
            })
        })
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }

    const handleNavClick = (id) => {
        setState((c) => {
            return ({
                ...c,
                currentDisplayIndex: 0
            })
        })
        if (state.windowWidth < 700) {
            setShowNav(false)
        }
        if (rootRef.current) {
            // console.log(rootRef.current.offsetHeight,state.appTop);

            if (id == 1) {

                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                })

            }
            if (id == 2) {
                window.scrollTo({
                    top: state.appTop,
                    behavior: "smooth"
                })
            }
        }
        setNavIndex(id)
    }

    const handleIconClick = () => {
        setShowNav(!showNav)
    }

    const handleLanguageClick = (id) => {
        setState((c) => {
            return ({
                ...c,
                languageIndex: id
            })
        })
    }

    const fontFamilyStyle = { fontFamily: state.languageIndex == 0 ? "Noto Serif JP, serif" : "Noto Serif SC, serif" }

    return (

        <div ref={trackNavRef} className={classes.navGroup} style={fontFamilyStyle}>
            <div className={classes.homeGroup} onClick={handleHomeNavClick}>
                <div className={classes.homeIcon}>
                    <img src={logo} className={classes.homeImg}></img></div>
                <span className={classes.jump}>Jump</span>
                <span className={classes.pixels}>Pixels</span>
            </div>
            {/* {state.windowWidth > 700 ? <div className={classes.others}>
                {navArr[state.languageIndex].map((x, i) => {
                    return (
                        <div onClick={() => { handleNavClick(i) }}
                            className={navIndex == i ? `${classes.singleNav} ${classes.highlight}` : classes.singleNav}>{i > 0 && x}</div>
                    )
                })} */}
            <div className={classes.others}>
                {languageArr[state.languageIndex].map((x, index) => {
                    return (
                        <div onClick={() => handleLanguageClick(index)}
                            className={state.languageIndex == index ? `${classes.highlightForLanguage} ${classes.language}` : classes.language}>{x}</div>
                    )
                })}
            </div>
        </div>
        // : <div className={classes.smallForOthers}>
        //     <div className={classes.iconNav} onClick={handleIconClick}>
        //         <FaBars />
        //     </div>
        //     {languageArr[state.languageIndex].map((x, index) => {
        //         return (
        //             <div onClick={() => handleLanguageClick(index)} className={state.languageIndex == index ? `${classes.highlightForLanguage} ${classes.language}` : classes.language}>{x}</div>
        //         )
        //     })}
        //     <div className={showNav ? classes.singleNavGroup : classes.noNavGroup}>
        //         {navArr[state.languageIndex].map((x, i) => {
        //             return (
        //                 <div onClick={() => handleNavClick(i)} className={showNav ? (navIndex == i ? `${classes.singleNav} ${classes.highlight}` : classes.singleNav) : classes.noNavGroup} >{i > 0 && x}</div>
        //             )
        //         })}
        //     </div>
        // </div>}
        // </div>

    )
}


export default Nav