import React, { useState, useContext } from 'react'
import { AppContext } from "../AppContext"
import { makeStyles } from '@material-ui/core/styles';

const titleArr = ["Disclaimer", "免责声明",]
const contextArr = [
    ["Copyrighted contents are the property of the copyright holders. All contents are shown free of charge for educational purposes only in accordance with fair use guidelines. If we have inadvertently included a copyrighted contents that the copyright holder does not wish to be displayed, we will take the contents down within 48 hours upon notification by the owner or the owner's legal representative.",
        "　　",
        "JumpyPixels.com is a non-profit site. Contents are published on this site purely for educational reasons, for the purpose of information and with good intentions. ",
        "　　",
        "The information and tools in this website are given AS IS WITHOUT ANY WARRANTY OF ANY KIND.",
        "JumpyPixels.com and its contributors will not be liable for any damage or loss resulting from ",
        "◦ The use of the content or the tools on this website.",
        "◦ Using this website means that you accept the Terms of Use.",
        "◦ These Terms of Use are subject to change at any time."
    ],
    [        "　　",
        "本站免费发布仅供学习参考，其观点不代表本站立场。",
        "　　",
        "凡本网站转载的所有的文章 、图片、音频、视频文件等资料的版权归版权所有人所有，本站采用的非本站原创文章及图片等内容无法一一和版权者联系，如果本网所选内容的文章作者及编辑认为其作品不宜上网供大家浏览，或不应无偿使用请及时用电子邮件通知我们，以迅速采取适当措施，避免给双方造成不必要的经济损失。"
    ],
]
const useStyles = makeStyles(theme => ({

    disclaimerContainer: {
        height: '80vh',
        textAlign: "left",
        lineHeight: "2.2rem",
        margin: "2rem auto",
        width: "80%",
        fontSize: "1.3rem"
    },
    title: {
        textAlign: "center",
        fontSize: "2rem",
        padding: "2rem"
    },
  
    "@media screen and (max-width: 700px)": {
        disclaimerContainer:{
            height:"auto",
            width: "90%",
            fontSize: "1rem"
        },
        title: {
            fontSize: "1.5rem",

        },
        paragraph: {
            fontSize: "1.2rem",
            padding: ".5rem"
        },
    }
}));

const ResponseAnouncement = () => {
    const [state, setState] = useContext(AppContext)
    const classes = useStyles();

    return (
        <div>
            <div className={classes.disclaimerContainer}>
                <div className={classes.title}>{titleArr[state.languageIndex]}</div>
                <div>
                    {contextArr[state.languageIndex].map((x, index) => {
                        return (
                            <div key={index}>
                                {x}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>

    )
}
export default ResponseAnouncement