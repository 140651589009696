import React, { useState, useContext, useEffect } from 'react'
import { AppContext } from "./AppContext"
import Home from "./components/Home"
import ResponseAnouncement from "./components/ResponseAnouncement"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  
    
}));

const Container = ( {rootRef} ) => {
    const [state, setState] = useContext(AppContext)
    const componentArr = [<Home rootRef={rootRef}/>, <ResponseAnouncement />]
    const classes = useStyles();

    const navTopHightStyle = {
        height: state.navTopHeight
    }
    const fontFamilyStyle = { fontFamily: state.languageIndex == 0 ? "Noto Serif JP, serif" : "Noto Serif SC, serif" }
   
    return (
        <div style ={fontFamilyStyle}>
            <div style={navTopHightStyle}></div>
            {componentArr[state.currentDisplayIndex]}
           
        </div>
    )
}
export default Container