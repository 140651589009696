import React, {useState, createContext} from 'react'

const AppContext = createContext([{},()=>{}])

 const AppProvider = (props) => {
     const [state, setState] = useState({
         isSpin: true,
         navTopHeight:0,
         windowWidth: null,
         currentDisplayIndex:0,
         appTop:0,
         languageIndex:0
       
     })

    return (
        <AppContext.Provider value={[state, setState]}>
            {props.children}
        </AppContext.Provider>
    )
}
export {AppContext, AppProvider}