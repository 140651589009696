import { useState, useRef, useEffect } from 'react'

// Get current state. Used to handle get future value of a state. Be carefor when used on an array, if not assigned an new array, state will change but stateRef won't change
// useEffect happened after state change, so don't use reference immediate after state change
const useRefState = initialValue => {
    const [state, setState] = useState(initialValue)
    const stateRef = useRef(state)
    useEffect(() => {
         stateRef.current = state
    },[state])
    return [state, stateRef, setState]
}

export default useRefState;