import React, { useContext,useRef } from 'react';
import './App.css';
import { AppProvider, AppContext } from "./AppContext"
import Nav from "./Nav"
import Container from "./Container"
import Footer from "./Footer"


function App(props) {
  const [state, setState] = useContext(AppContext)
  const trackRootRef = useRef("")
  // let parms;
  // if (props)
  // {
  //   parms = props.match.params;
  // }

  return (
    <AppProvider>
      <Nav rootRef ={trackRootRef}/>
      <Container rootRef ={trackRootRef}/>
      <Footer /> 
    </AppProvider>
  );
}

export default App;
