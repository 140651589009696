import React, { useState, useRef, useEffect, useContext } from 'react'
import { AppContext } from "../AppContext"
import "./FadeInSection.css"

export default function FadeInSection(props) {
  const [state, setState] = useContext(AppContext)
  const [isVisible, setVisible] = React.useState(true);
  const domRef = React.useRef();
  React.useEffect(() => {

    {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
      });
      observer.observe(domRef.current);
      return () => {
        try { observer.unobserve(domRef.current); }
        catch (e) {

        }
      }
    }
  }, []);
  return (
    <div
      className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
      ref={domRef}
    >
      {props.children}
    </div>
  );
}