
import React, { useState, useEffect, useRef } from "react";
import useRefState from "../utility/useRefState"
import frameImage from "../images/apps/phoneframe1.png";
// import image1 from "./images/read1.jpg";
// import image2 from "./images/read2.jpg";
// import image3 from "./images/read3.jpg";
// import image4 from "./images/read4.jpg";

// const images = [image1, image2, image3, image4];


export default function ScrollPhone({ images, imgH }) {
  const imgsRef = useRef([]);
  const [targetImgs, setTargetImgs] = useState([images[0], images[1]]);
  const [scroll, setScroll] = useState(false);
  const [currentImg, currentImgRef,setCurrentImg] = useRefState(0);



  const imgW = imgH / 2;

  const container = {
    // maxWidth: '40rem',
    width: `${imgW}px`,
    height: `${imgH}px`,
    //   background: "#D0C0A0",
    background: "transparent",
    // margin: "0 auto",
    // padding: "1rem",
    position: "relative",
    borderImageSource: `url(${frameImage})`,
    borderImageSlice: "132 fill", // Cut actual corner size
    borderImageWidth: "50px", // Shrink
    borderImageOutset: "10px", // outset border,
    overflow: "hidden",
  };
  const frameStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: `${imgW}px`,
    height: `${imgH}px`,
    //   background: "#D0C0A0",
    background: "transparent",
    margin: "0 auto",
    padding: "1rem",
    position: "relative",
    borderImageSource: `url(${frameImage})`,
    borderImageSlice: "132 fill", // Cut actual corner size
    borderImageWidth: "50px", // Shrink
    borderImageOutset: "10px", // outset border,
  }
  const transitionTime = 1;

  useEffect(() => {
    if (imgsRef.current) {
      for (let i = 0; i < 2; i++) {
        imgsRef.current[i].style.left = `${i * imgW}px`;
      }
    }
    let itv = setInterval(() => {
      scrollImages();
    }, 5000);

    return (() => { clearInterval(itv); });
  }, []);

  const scrollImages = () => {

    setScroll(true);
    setTimeout(() => {
      setScroll(false);
      let cm = currentImgRef.current + 1;
      cm = cm >= images.length ? 0 : cm;
      let nextImg = cm + 1;
      nextImg = nextImg >= images.length ? 0 : nextImg;
      setCurrentImg(cm)
      setTargetImgs([images[cm], images[nextImg]]);
    }, 3000);
  };

  return (
    <div style={container}>
      {targetImgs.map((img, idx) => {
        return (
          <img
            ref={(r) => {
              imgsRef.current[idx] = r;
            }}
            src={img}
            style={{
              position: "absolute",
              top: 6,
              left: scroll ? (idx - 1) * imgW : idx * imgW,
              width: `${imgW}px`,
              height: `${imgH-20}px`,
              zIndex: 0,
              transition: scroll ? `${transitionTime}s ease-in-out` : "none",
            }}
            alt=""
          />
        );
      })}
      <div style={frameStyle}></div>

    </div>
  );
}

