import React, { useEffect, useContext, useRef, useState } from 'react'
import { AppContext } from "./AppContext"
import { makeStyles } from '@material-ui/core/styles';
import footerImg1 from "./images/footerImg1.jpg"
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles(theme => ({
    footerContainer: {
        width: "100%",
        margin: "0 auto",
        marginTop: "1rem",
      
        display: "flex",
        justifyContent: "center",
        background: "rgba(235,235,235)",
        padding: "0 5rem",
        fontSize: "1.1rem",
        color: "black",
        // backgroundImage: `url(${footerImg1})`,
        backgroundSize: "cover",
        backgroundPosition: "top",

    },
    footerItem: {
        padding: ".5rem 1rem",
        border: "1px solid rgb(177,144,144)",
        margin: ".5rem 2rem",
        cursor: "pointer"
    },
    "@media screen and (max-width: 810px)": {
        footerContainer: {
            width: "100%",
            fontSize: ".8rem",
            padding: "0 .5rem",
            marginBottom:"8vh",
        },
        footerItem: {
            padding: ".3rem 1rem",
            margin: ".5rem 0.5rem",
        },
    }
}));
const contactArr = [ "Contact us","联系我们", ]
const emailArr = ["Email us","给我们电邮",  ]
const questionArr = [ "Questions, Suggestions","问题，建议等", ]
const concelArr = ["Concel","取消",  ]
const submitArr = [ "Submit","提交", ]
const commentArr = [ "Comments","意见纠错", ]
const emailAddressArr = ["Email Address","电邮地址", ]
const disclaimerArr = ["Disclaimer","免责声明", ]

 const Footer = () => {
    const [state, setState] = useContext(AppContext)
    const classes = useStyles();
    const trackFooterRef = useRef(null)


    const handleResponseWaiverClick = () => {
        window.scrollTo(0, 0)
        setState((s) => {
            return ({
                ...s,
                currentDisplayIndex: 1,
            })
        })
    }
   
    const [open1, setOpen1] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);
    const [value1, setValue1] = useState("")
    const [email1, setEmail1] = useState("")
    const [value3, setValue3] = useState("")
    const [email3, setEmail3] = useState("")

    const handleClickOpen1 = () => {
        setOpen1(true);
    };
    const handleClose1 = () => {
        setOpen1(false);
    };

    const handleClickOpen3 = () => {
        setOpen3(true);
    };
    const handleClose3 = () => {
        setOpen3(false);
    };
    const handleChangeEmail1 = (e) => {
        setEmail1(e.target.value)
    }
    const handleChangeValue1 = (e) => {
        setValue1(e.target.value)
    }
    const handleChangeEmail3 = (e) => {
        setEmail3(e.target.value)
    }
    const handleChangeValue3 = (e) => {
        setValue3(e.target.value)
    }
    const handleClickCancelEmail1 = () => {
        setValue1("")
        setEmail1("")
        setOpen1(false)
    }
    const handleClickSubmit1 = (e) => {
        e.preventDefault()
        if (value1.length !== 0 && email1.length !== 0) {
            // getEmail(value1, email1, 1)
            setValue1("")
            setEmail1("")
        } else {
            alert("请输入内容！")
        }
        setOpen1(false)
    }
    const handleClickCancelEmail3 = () => {
        setValue3("")
        setEmail3("")
        setOpen3(false)
    }
    const handleClickSubmit3 = (e) => {
        e.preventDefault()
        if (value3.length !== 0 && email3.length !== 0) {
            // getEmail(value3, email3,3)
            setValue3("")
            setEmail3("")
        } else {
            alert("请输入内容！")
        }
        setOpen3(false)
    }
    return (
        <div ref={trackFooterRef} className={classes.footerContainer}>
        <div>
            <div onClick={handleClickOpen1} className={classes.footerItem}>{contactArr[state.languageIndex]}</div>

            <Dialog open={open1} onClose={handleClose1} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title"> {emailArr[state.languageIndex]}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {questionArr[state.languageIndex]}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        value={email1}
                        id="name"
                        label={emailAddressArr[state.languageIndex]}
                        type="email"
                        fullWidth
                        onChange={(e) => handleChangeEmail1(e)}
                    />
                    <TextField
                        margin="dense"
                        id="name"
                        value={value1}
                        label={questionArr[state.languageIndex]}
                        type="text"
                        multiline="true"
                        fullWidth
                        onChange={(e) => handleChangeValue1(e)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose1} color="primary" onClick={handleClickCancelEmail1}>
                        {concelArr[state.languageIndex]}
                            </Button>
                    <Button onClick={handleClose1} color="primary" onClick={handleClickSubmit1}>
                        {submitArr[state.languageIndex]}
                            </Button>
                </DialogActions>
            </Dialog>
        </div>
        <div onClick={handleResponseWaiverClick} className={classes.footerItem}>{disclaimerArr[state.languageIndex]}</div>
        <div>
            <div onClick={handleClickOpen3} className={classes.footerItem}>{commentArr[state.languageIndex]} </div>

            <Dialog open={open3} onClose={handleClose3} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{emailArr[state.languageIndex]}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    {commentArr[state.languageIndex]}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        value={email3}
                        id="name"
                        label={emailAddressArr[state.languageIndex]}
                        type="email"
                        fullWidth
                        onChange={(e) => handleChangeEmail3(e)}
                    />
                    <TextField
                        margin="dense"
                        id="name"
                        value={value3}
                        label={commentArr[state.languageIndex]}
                        type="text"
                        multiline="true"
                        fullWidth
                        onChange={(e) => handleChangeValue3(e)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose3} color="primary" onClick={handleClickCancelEmail3}>
                        {concelArr[state.languageIndex]}
                            </Button>
                    <Button onClick={handleClose3} color="primary" onClick={handleClickSubmit3}>
                        {submitArr[state.languageIndex]}
                            </Button>
                </DialogActions>
            </Dialog>

        </div>
    </div>
    )
}
export default Footer