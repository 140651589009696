import React, { useState, useContext, useEffect, useRef } from "react";
import { AppProvider, AppContext } from "../AppContext";
import ScrollPhone from "../utility/ScrollPhone";
import FadeInSection from "../utility/FadeInSection";
import { makeStyles } from "@material-ui/core";
import homeImg1 from "../images/homeImg1.jpg";
import homeImg2 from "../images/homeImg2.jpg";
import homeImg3 from "../images/homeImg3.jpg";
import homeImg4 from "../images/homeImg4.jpg";
import homeImg5 from "../images/homeImg5.jpg"
import homeImg6 from "../images/homeImg6.jpg"
import homeImg7 from "../images/homeImg7.jpg"
import smallImg1 from "../images/smallImg1.jpg";
import smallImg2 from "../images/smallImg2.jpg";
import smallImg3 from "../images/smallImg3.jpg";
import smallImg4 from "../images/smallImg4.jpg";
import smallImg5 from "../images/smallImg5.jpg";
import smallImg6 from "../images/smallImg6.jpg";
import smallImg7 from "../images/smallImg7.jpg";
import smallImg8 from "../images/smallImg8.jpg";
import smallImg9 from "../images/smallImg9.jpg";
import collection1 from "../images/apps/collection1.jpg";
import collection2 from "../images/apps/collection2.jpg";
import collection3 from "../images/apps/collection3.jpg";
import collection4 from "../images/apps/collection4.jpg";
import collection5 from "../images/apps/collection5.jpg";
import collection6 from "../images/apps/collection6.jpg";
import poet1 from "../images/apps/poet1.jpg";
import poet2 from "../images/apps/poet2.jpg";
import poet3 from "../images/apps/poet3.jpg";
import poet4 from "../images/apps/poet4.jpg";
import poet5 from "../images/apps/poet5.jpg";
import poet6 from "../images/apps/poet6.jpg";
import recite1 from "../images/apps/recite1.jpg";
import recite2 from "../images/apps/recite2.jpg";
import recite3 from "../images/apps/recite3.jpg";
import recite4 from "../images/apps/recite4.jpg";
import recite5 from "../images/apps/recite5.jpg";
import recite6 from "../images/apps/recite6.jpg";
import recite7 from "../images/apps/recite7.jpg";
import recite8 from "../images/apps/recite8.jpg";
import recite9 from "../images/apps/recite9.jpg";
import recite10 from "../images/apps/recite10.jpg";
import recite11 from "../images/apps/recite11.jpg";
import recite12 from "../images/apps/recite12.jpg";
import memorize1 from "../images/apps/memorize1.jpg";
import memorize2 from "../images/apps/memorize2.jpg";
import memorize3 from "../images/apps/memorize3.jpg";
import memorize4 from "../images/apps/memorize4.jpg";
import memorize5 from "../images/apps/memorize5.jpg";
import memorize6 from "../images/apps/memorize6.jpg";
import memorize7 from "../images/apps/memorize7.jpg";
import memorize8 from "../images/apps/memorize8.jpg";
import memorize9 from "../images/apps/memorize9.jpg";
import covid1 from "../images/apps/covid1.jpg";
import covid2 from "../images/apps/covid2.jpg";
import covid3 from "../images/apps/covid3.jpg";
import covid4 from "../images/apps/covid4.jpg";
import covid5 from "../images/apps/covid5.jpg";
import covid6 from "../images/apps/covid6.jpg";
import appLogo1 from "../images/apps/appLogo1.png";
import appLogo2 from "../images/apps/appLogo2.png";
import appLogo3 from "../images/apps/appLogo3.png";
import appLogo4 from "../images/apps/appLogo4.png";
import appLogo5 from "../images/apps/appLogo5.png";
import githublogo from "../images/githublogo.png"

// const imgArr = [homeImg1, homeImg2, homeImg3, homeImg4, homeImg5, homeImg6,];
// const smallImgArr = [smallImg1, smallImg2, smallImg3, smallImg4, smallImg5, smallImg6,];
// const smallImgArr2 = [smallImg1, smallImg2, smallImg3, smallImg8, smallImg7, smallImg6,];
const imgArr = [homeImg1, homeImg2, homeImg3, homeImg4, homeImg5, homeImg6,];
const smallImgArr = [smallImg1, smallImg2, smallImg3, smallImg4,   smallImg5, smallImg6,];
const smallImgArr2 = [smallImg1, smallImg2, smallImg3,smallImg4,   smallImg7, smallImg6,];
const linkArr = [
  "https://shiyuci.org/",
  "https://enteringchan.com/",
  "https://jumpypixels.com/covidtally",
  // "https://jumpypixels.com/stockchart",
  "https://jumpypixels.com/weather",
  "https://jumpypixels.com/restaurant",
   "https://jumpypixels.com/expensemanager"
];
const navTextArr = [
  "ShiYuCi.org",
  "EnteringChan.com",
  "jumpypixels.com/covidtally",
  "jumpypixels.com/weather",
  // "https://jumpypixels.com/stockchart",
  "jumpypixels.com/restaurant", "jumpypixels.com/expensemanager"
];

const startText = [
  "Welcome to my website!",
  "欢迎使用我们的网站并安装Apps！",
];
const introductionTextArr = [
  [
    [
      "website for chinese ancient poetry lovers including 850,000+ poems and ~30,000 authors",
      "built with full stack architecture including next js, node js and mongodb",
      "utilize the server side rendering of next js",
      "provide full texts search of contents",
      "support different screen sizes"
    ],
    [
      "专为古诗词爱好者设计",
      "超过85万首古诗词，将近3万名作者",
      "收集了17部诗词集",
      "涵盖了从先秦到当代的作者与诗词曲",
      "设置了不同的背景，主题供读者享用",
    ],
  ],
  [
    [
      "website for the entry level introduction of Chan Buddhism in both chinese and english",
      "built with next js",
      "utilize the static/server side rendering of next js to improve the performance of SEO",
      "support different screen sizes"
    ],
    [
      "专门介绍禅宗的入门网站",
      "超过200个公案故事",
      "53位祖师大德的生平介绍",
      "介绍与禅宗相关的诗词，绘画以及饮食",
      "禅宗经典著作与经文可供阅读",
      "中英双语",
    ],
  ],
  [
    ["a website built with react hooks to visualize global covid-19 development",
    "customized line charts and bar chart with d3.js to show cases development trends",
    "customized tables with js and css grid to display confirmed, fatal and new daily cases ",
    "use d3-geo to show cases distributions in us, states and global maps",
    "support different screen sizes"],


    ["以表格的方式显示美国和全球当天新冠确诊总数，新增人数，死亡总数和新增死亡人数",
      "以图表的方式显示美国及其各州，全球及各国疫情发展趋势",
      "各国和美国各州，各郡疫情数据也有显示",
      "图表有分类功能，方便查找",
    ]
  ],
  // [
  //   [
  //     "display stock chart with indicator",
  //     "made with vanilla js and html canvas",
  //     "source code available in github",
  //   ],
  //   ["使用 vanilla js 和 html canvas设计",
  //     "显现股票价格和时间",
  //     "代码可见github",]
  // ],
  [
    [
      "Us weather website built with react hooks",
      "fetch data from pubic servers (api.openweathermap.org and api.weather.gov) by city or zipcode",
      "display hourly, daily and weekly weather",
      "Background changed based on weather conditions",
      "support different screen sizes"
    ],
    [
      "全美天气预报",
      "通过邮政编码，城市名查询",
      "显示每天，小时以及每周的天气情况",
      "网站背景可根据不同天气情况变化",
    ],
  ],
  [
    ["a restaurant website built with react hooks",
      "source code available in github",
      "display different layouts of dishes using js and css grid",
      "apply @material-ui",
      "support different screen sizes",],
    ["餐馆网站练习项目",
      "不同的菜系显现",
      "使用 react hooks，js， css grid以及@material-ui设计",
      "适用于不同屏幕",]
  ],
  [
    ["a expenses management website built with react hooks",
      "source code available in github",
      "utilize Recharts to show expenditure distributions",
      "visualize the expenditure based on weekly, monthly, biennially and yearly",
      "support different screen sizes"],
    [
      "以图表的方式显示各类开销",
      "可以看到每周，每月，每半年，全年以及所有花费",
      "图表有分类功能，方便查找",
      "适用于不同屏幕",
    ]
  ],
];

const mainTextArr = [
  [
    ["an App built with flutter to visualize global covid-19 information in mobile",
      "source code available in github",
      "fetch raw data from github resource (/CSSEGISandData/COVID-19)",
      "display confirmed, fatal and new daily cases in customized tables using dart and flutter widgets",
      "use charts_flutter to show Cases development trends",
    ],
    ["以表格的方式显示美国和全球当天新冠确诊总数，新增人数，死亡总数和新增死亡人数",
      "以图表的方式显示美国及其各州，全球及各国疫情发展趋势",
      "各国和美国各州，各郡疫情数据也有显示",
      "图表有分类功能，方便查找",
    ]
  ],
  [
    [
      "an app collected 17 chinese ancient poetry collections",
      "built with Flutter",
      "fetch raw data from remote sever",
      "display each collection by indexes, authors and chapters",
      "present poem with detail appreciation, translation to modern chinese style, poem background, famous sentences if having and poet biography",
      "AI Voice Reading function available",
      "provide customized setting"
    ],
    [
      "收集了17部中国古代诗集",
      "按目录、作者、章节分类，方便读者阅读",
      "诗词具有翻译， 注释，赏析，背景欣赏，并显示名句",
      "读者可以读到大部分诗人的生平介绍",
      "AI 语音朗读诗词"
    ],
  ],
  [
    ["app containing 29469 poets and their works",
      "built with Flutter",
      "multiple way to access poets by searching",
      "poets covered from pre-Qin to modern age",
      "fetch raw data from remote sever",
      "AI Voice Reading function available",
      "provide customized setting"
    ],
    [
      "收集了29469位诗人的作品, 部分诗人有生平介绍",
      "多种方便途径可以搜寻到你喜欢的诗人",
      "诗人涵盖了先秦到当代",
      "读者可以收藏喜欢的诗人及其作品",
      "AI 语音朗读诗词"
    ],
  ],
  [
    ["an app for daily memorizing poem from 17 poetry collections",
      "built with Flutter",
      "multiple ways for users to memorize poem",
      "a check list availble to remind users their progress",
      "fetch raw data from remote sever",
      "AI Voice Reading function available",
      "provide customized setting"
    ],
    ["用户从17本诗集中任意挑选诗词和诗集",
      "每天显示一首诗词供用户背诵",
      "用户可以用多种方式背诵诗词",
      "可提醒用户背诵诗歌的进展情况",
      "用户可以收藏喜欢的作品",
      "AI 语音朗读诗词"
    ],
  ],

  [
    ["an app for memorizing poem based on the selection of users' favorites",
      "multi-category poem themes selected by users",
      "enable user to memorize a poem on daily or weekly basis",
      "built with Flutter",
      "fetch raw data from remote sever",
      "AI Voice Reading function available",
      "provide customized setting"
    ],
    ["用户可以选取自己喜欢的诗词进行背诵",
      "多渠道任意挑选并收藏诗词--包括诗集，诗人，主题和查询",
      "选择时间间隔如每天，每周来背诵诗词",
      "多种方式包括听，看，长短句填空来反复背诵诗词",
      "诗歌背诵的进展顺序由用户收藏作品而定",
      "记录用户背诵诗歌的进展情况",
      "AI 语音朗读诗词"
    ]
  ],

];
const introductionTextArrForSmall1 = [
  [
    [
      "website for chinese ancient poetry lovers including 850,000+ poems and ~30,000 authors",
      "built with full stack architecture including next js, node js and mongodb",
    ],
    [
      "专为古诗词爱好者设计",
      "超过85万首古诗词，将近3万名作者",
      "收集了17部诗词集",
    ],
  ],
  [
    [
      "website for the entry level introduction of Chan Buddhism in both chinese and english",
      "built with next js",
    ],
    ["专门介绍禅宗的入门网站", "超过200个公案故事", "53位祖师大德的生平介绍"],
  ],
  [
    [
      "a website built with react hooks to visualize global covid-19 developments",
      "customized line charts and bar chart with d3.js",
      "customized tables using js and css grid",

    ],
    ["以表格的方式显示美国和全球当天新冠确诊总数，新增人数，死亡总数和新增死亡人数",
      "以图表的方式显示美国及其各州，全球及各国疫情发展趋势",
    ],
  ],
  [
    [
      "a Us weather website built with react hooks",
      "source code available in github",
      "fetch data from pubic servers (api.openweathermap .org and api.weather.gov)",

    ],
    ["全美天气预报",
      "通过邮政编码，城市名查询",
    ],
  ],
  [
    ["a restaurant website built with react hooks",
      "apply @material-ui",

    ],
    ["餐馆网站练习项目",
      "不同的菜系显现",
    ]
  ],
  [
    ["an expenses management website built with react hooks",
      "source code available in github",
      "show expenditure distributions with recharts",


    ],
    ["以图表的方式显示各类开销",
      "可以看到每周，每月，每半年，全年以及所有花费",
    ]
  ],
];
const introductionTextArrForSmall2 = [
  [
    ["utilize the server side rendering of next js to improve the SEO of the app",
      "provide full texts search of contents",
      "support different screen sizes"],
    ["涵盖了从先秦到当代的作者与诗词曲", "设置了不同的背景，主题供读者享用"],
  ],
  [
    ["utilize the static/server side rendering of next js to improve the performance of SEO",
      "support different screen sizes"],
    [
      "介绍与禅宗相关的诗词，绘画以及饮食",
      "禅宗经典著作与经文可供阅读",
      "中英双语",
    ],
  ],
  [
    [
      "use d3-geo to show distributions in maps",
      "support different screen sizes"
    ],
    ["各国和美国各州，各郡疫情数据也有显示",
      "图表有分类功能，方便查找",],
  ],
  [
    [
      "display hourly, daily and weekly weather",
      "Background changed based on weather conditions",
      "support different screen sizes"
    ],
    [
      "显示每天，小时以及每周的天气情况",
      "网站背景可根据不同天气情况变化",
    ],
  ],
  [
    ["display different layouts using js and css grid",
      "support different screen sizes",],
    ["使用 react hooks，js， css grid以及@material-ui设计",
      "适用于不同屏幕",]
  ],
  [
    ["visualize the expenditure on weekly, monthly, biennially and yearly basis",
      "customized tables using js and css grid",
      "support different screen sizes",],
    ["图表有分类功能，方便查找",
      "适用于不同屏幕",]
  ],
];

const colorArr = [
  "rgb(27,130,213)",
  "rgb(4,63,55)",
  "rgb(46,121,136)",
  "rgb(4,35,72)",
  "rgb(55,96,55)", "rgb(83,189,177)",
];
const colorBackgroundArr = [
  "rgba(27,130,213,.7)",
  "rgba(4,63,55,.7)",
  "rgba(46,121,136,.7)",
  "rgba(4,35,72,.7)",
  "rgba(55,96,55,.7)", "rgba(83,189,177,.7)",
];
const webTextColorArr = ["#925101", "#974143", "#883d2e", "#3b1c00", "#603760", "#550919",];

// const appObj = {
//   "poetry Collections": [
//     collection1,
//     collection2,
//     collection3,
//     collection4,
//     collection5,
//     collection6,
//   ],
//   "collection Of Poets": [poet1, poet2, poet3, poet4, poet5, poet6],
//   "Recite a Poem Daily": [recite1, recite2, recite3, recite4, recite5, recite6],
//   "classics of chan sutra": [sutra1, sutra2, sutra3, sutra4, sutra5, sutra6],
// };
const topAppTitle = ["Apps", "应用程序"];
const appCollection = [
  ["Covid-19 Global Summary", "新冠疫情全球播报", "covidtally.apk", "https://github.com/zenlink/Coronavirus-COVID-19-"],
  ["poetry Collections", "古诗词集", "poemcollection.apk", "https://play.google.com/store/apps/details?id=com.jumpypixels.poemcollection"],
  ["collection Of Poets", "诗人大全", "poetcollection.apk", "https://play.google.com/store/apps/details?id=com.jumpypixels.selectivepoet"],
  ["Daily Poem Memorizer", "每日背诗", "poemrecite.apk", "https://play.google.com/store/apps/details?id=com.jumpypixels.poemrecite"],
  ["Recite Poems", "爱背诗", "poemrecite.apk", "https://play.google.com/store/apps/details?id=com.jumpypixels.poemrecite"],

];

const maxWidth = 1500;
const buttonName = ["Download", "本地下载"];
const appLogo = [appLogo5, appLogo1, appLogo2, appLogo3, appLogo4,];
const appImgArr = [
  [covid1, covid2, covid3, covid4, covid5, covid6],
  [
    collection1,
    collection2,
    collection3,
    collection4,
    collection5,
    collection6,
  ],
  [poet1, poet2, poet3, poet4, poet5, poet6],
  [
    recite1,
    recite2,
    recite3,
    recite4,
    recite5,
    recite6,
    recite7,
    recite8,
    recite9,
    recite12,
  ],

  [memorize1, memorize2, memorize3, memorize4, memorize5, memorize6, memorize7, memorize8, memorize9],


];

const commonButton = {
  background: "rgb(4,170,109)",
  color: "white",
  fontSize: "1.2rem",
  border: "0",
  borderRadius: "5px",
  margin: '10px 15px',
  height: '50px',
  width: '165px',

}
const useStyles = makeStyles((theme) => ({
  topText: {
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1.2rem",
    zIndex: 100,
  },

  carouselGroup: {
    margin: '0 auto',
    position: "relative",
    height: "90vh",
    maxWidth: maxWidth,
    overflow: "hidden",
    zIndex: 1,
    cursor: "pointer",
    minHeight: "700px",
  },

  centerImg: {
    position: "absolute",
    width: "60%",
    // height: "60%",
    objectFit: "cover",
    objectPosition: "top",
    animationFillMode: "forwards",
    visibility: "hidden",
    // animationName: "image",
    animationDuration: "3s",
    // top: "10%",
    left: "20%",
  },
  transparentBackground1: {
    position: "absolute",
    width: "46%",
    height: "40%",
    animationFillMode: "forwards",
    visibility: "hidden",
    animationDelay: "1s",
    animationDuration: "3s",
    top: "5%",

  },

  introductionText: {
    display: "grid",
    alignItems: "center",
    padding: "2rem 3rem",
    height: "100%",
    fontSize: "1.4rem",
    textTransform: "capitalize",
    color: "white",
  },

  transparentBackground2: {
    position: "absolute",
    width: "20%",
    // height: "60%",
    animationFillMode: "forwards",
    visibility: "hidden",
    animationDuration: "3s",
    animationDelay: "2s",
    top: "30%",
  },
  smallImg: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "top",
  },
  subText: {
    position: "absolute",
    fontSize: "3rem",
    // background: "rgb(4,170,109,.2)",
    // animation: "subtext",
    animationDuration: "3s",
    animationDelay: "3s",
    animationFillMode: "forwards",
    visibility: "hidden",
    bottom: "23%",
    fontFamily: "Abril Fatface, cursive;",
    // left: "40%",
    // color: "white",
    fontweight: "bold",
  },
  textContent: {
    textAlign: "center",
    padding: "1rem 3rem",
  },
  dotGroup: {
    position: "absolute",
    display: "flex",
    bottom: "5%",
    left: "40%",
  },
  dot: {
    height: "30px",
    width: "30px",
    margin: "0 .5rem",
    borderRadius: "50%",
    cursor: "pointer"
  },

  appContainer: {
    width: "80%",
    maxWidth: maxWidth,
    margin: "0 auto",
    paddingTop: "2rem"
  },
  appMainTitle: {
    fontSize: "1.5rem",
    // color: "rgb(4,170,109)",
    fontweight: "bold",
    paddingBottom: "2rem",
  },
  appLogoImgContainer: {
    display: "inline-block",
    height: "40px",
    width: "40px",
  },
  appLogo: {
    width: "100%",
    objectFit: "cover",
    objectPosition: "center",
  },
  appTitle: {
    fontSize: "2rem",
    paddingBottom: "2rem",
    textTransform: "capitalize",
    color: "#014292",
    fontweight: "bold",
    paddingLeft: ".5rem",
  },
  appGroup1: {
    marginBottom: "2rem",
    border: "1px solid rgba(0,0,0,0.125)",
    boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.12), 0 5px 5px rgba(0, 0, 0, 0.24)",
    padding: "3rem",
    background: "rgb(240,240,240)",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
  appDescription: {
    padding: "2rem",
    fontSize: "20px",
  },
  applist: {
    textTransform: "capitalize",
    padding: ".3rem 0"
    // lineHeight: "1.5rem",
  },
  scrollPhone: {
    justifySelf: "center",
    paddingTop: "5rem",
  },
  buttonContainer: {
    margin: "0 auto",
    textAlign: "center",
    padding: "3rem 0",
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    ...commonButton,
    padding: "0.5rem 2rem",
    cursor: 'pointer'
  },
  button1: {
    ...commonButton,
    padding: "0.5rem 1rem",
  },
  "@media screen and (max-width: 1500px)": {
    introductionText: {
      fontSize: "1.25rem",
    },
  },
  "@media screen and (max-width: 1300px)": {
    // transparentBackground1: {
    //     height: "33%",
    // },
    // carouselGroup: {
    //   height: "80vh",
    //   minHeight:"500px",
    // },
    introductionText: {
      fontSize: "1.1rem",
    },
    subText: {
      fontSize: "2.5rem",
    },

    dot: {
      height: "20px",
      width: "20px",

    },
    scrollPhone: {
     
      paddingTop: "6rem",
    },
  },
  "@media screen and (max-width: 1000px)": {
    centerImg: {
      height: "50%",
    },
    transparentBackground1: {
      width: "55%",
      height: "35%",
    },
    transparentBackground2: {
      width: "30%",
      height: "50%",
    },
    introductionText: {
      padding: "1rem 3rem",
    },
    subText: {
      fontSize: "2rem",
      bottom: "33%",
    },

  },
  "@media screen and (max-width: 800px)": {
    carouselGroup: {
      height: "80vh",
      minHeight: "500px",
    },
    appContainer: {
      width: "95%",
    },
    appGroup1: {
      padding: '2rem',
      gridTemplateColumns: "1fr",
    },
    scrollPhone: {
      paddingTop: "2rem",
    },
  },
  "@media screen and (max-width: 700px)": {
    carouselGroup: {
      height: "80vh",
      minHeight: "500px",
    },
    topText: {
      padding: "1rem",
    },
    dotGroup: {
      left: "25%",
      bottom: "2%",
    },
    dot: {
      height: "15px",
      width: "15px",

    },
    centerImg: {
      width: "65%",
      height: "65%",
      left: "17%",
    },
    transparentBackground1: {
      width: "60%",
      height: "45%",
      // top: "10%",
    },
    introductionText: {
      padding: "1rem .5rem 1rem 1.5rem",
      fontSize: ".95rem",
    },
    transparentBackground2: {
      width: "60%",
      height: "45%",
      top: "45%",
    },
    subText: {
      fontSize: "1.5rem",
      width: "100%",
    },
    textContent: {
      padding: ".5rem",
    },
    appLogoImgContainer: {
      height: "30px",
      width: "30px",
    },
    appLogo: {
      width: "80%",
    },
    appTitle: {
      fontSize: "1.3rem",

    },
    appDescription: {
      fontSize: "1.1rem",
      padding: '2rem 1rem',

    },
 
    // appGroup2: {
    //   gridTemplateColumns: "1fr",
    // },
    buttonContainer: {
      // display: "block",
      flexDirection: "column",
      padding: "0",
    },
    button: {
      fontSize: "1.0rem",
    },
    button1: {
      fontSize: "1.0rem",
    },
   
    "@media screen and (max-width: 325px)": {
      introductionText: {
        fontSize: ".9rem",
      },
    }
  },
}));

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
let initialized = false
function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

let isAndroid = false;
const Home = ({ rootRef }) => {
  const [state, setState] = useContext(AppContext);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const trackImgRef = useRef("");
  const trackbackgroundIRef = useRef("");
  const trackbackgroundIIRef = useRef("");
  const trackCarouselTextRef = useRef("");
  const tracksmallImgRef = useRef("");
  // const trackRootRef = useRef("")
  const trackAppRef = useRef("");
  const classes = useStyles();

  useEffect(() => {
    initialized = true
    let interval = setInterval(() => {
      let headerOffset = state.navTopHeight;
      let elementPosition;
      let offsetPosition;
      isAndroid = (getMobileOperatingSystem() === "Android");
      if (trackAppRef.current) {
        elementPosition = trackAppRef.current.getBoundingClientRect().top;
        offsetPosition = elementPosition - headerOffset;

        setState((s) => {
          return {
            ...s,
            appTop: offsetPosition,
          };
        });
        clearInterval(interval);
      }
    }, 100);
  }, []);

  let l;
  useEffect(() => {
    l = imgArr.length;
    if (trackImgRef.current) {
      trackImgRef.current.style.animationName = "image";
      trackbackgroundIRef.current.style.animationName = "background1";
      trackbackgroundIIRef.current.style.animationName = "background2";
      trackCarouselTextRef.current.style.animationName = "subtext";
    }
    let si = setInterval(changeImg, 10000);
    return () => {
      clearInterval(si);
    };
  }, []);

  const changeImg = () => {
    setCurrentIndex((i) => {
      return i > -1 && i < l - 1 ? i + 1 : 0;
    });
      if( tracksmallImgRef.current){
      tracksmallImgRef.current.style.animationName = "smallimage";
    }
    trackImgRef.current.style.animationName = "none";
    trackbackgroundIRef.current.style.animationName = "none";
    trackbackgroundIIRef.current.style.animationName = "none";
    trackCarouselTextRef.current.style.animationName = "none";

    setTimeout(() => {
      trackImgRef.current.style.animationName = "image";
      trackbackgroundIRef.current.style.animationName = "background1";
      trackbackgroundIIRef.current.style.animationName = "background2";
      trackCarouselTextRef.current.style.animationName = "subtext";
    }, 200);
  };

  const handleDotClick = (id) => {
    setCurrentIndex(id)
  }

  const navTopHightStyle = {
    height: state.navTopHeight,
    background: colorArr[currentIndex],
  };
  const background1ColorStyle = {
    background: colorBackgroundArr[currentIndex],
  };
  const textColorStyle = {
    color: webTextColorArr[currentIndex],
    fontweight: "bold",
    background: "rgba(200,200,220,.5)",

  };
  const selesctDotColorStyle = {
    background: "lightgrey"
  }

  return (
    // <div ref={rootRef} style={{ width: '100vw', height: `calc(100vh - ${state.navTopHeight}px)`, overflow: 'auto' }} >
    <div ref={rootRef}>
      <div style={navTopHightStyle} className={classes.topText}>
        {startText[state.languageIndex]}
      </div>
      <div className={classes.carouselGroup}>
        <a target="_blank" href={linkArr[currentIndex]}>
          <img
            ref={trackImgRef}
            className={classes.centerImg}
            src={
              window.innerWidth > 700
                ? imgArr[currentIndex]
                : smallImgArr2[currentIndex]
            }
          ></img>

          <div
            style={background1ColorStyle}
            ref={trackbackgroundIRef}
            className={classes.transparentBackground1}
          >
            <ul className={classes.introductionText}>
              {(window.innerWidth > 700
                ? introductionTextArr[currentIndex][state.languageIndex]
                : introductionTextArrForSmall1[currentIndex][
                state.languageIndex
                ]
              ).map((x, index) => {
                return <li className={classes.singleUl}>{x}</li>;
              })}
            </ul>
          </div>
          <div
            ref={trackbackgroundIIRef}
            className={classes.transparentBackground2}
            style={window.innerWidth > 700 ? null : background1ColorStyle}
          >
            {window.innerWidth > 700 ? (
              initialized && <img
                ref ={tracksmallImgRef}
                className={classes.smallImg}
                src={smallImgArr[currentIndex]}
              ></img>
            ) : (
              <ul className={classes.introductionText}>
                {introductionTextArrForSmall2[currentIndex][
                  state.languageIndex
                ].map((x, index) => {
                  return <li className={classes.singleUl}>{x}</li>;
                })}
              </ul>
            )}
          </div>
          <div ref={trackCarouselTextRef} className={classes.subText}>
            <div style={textColorStyle} className={classes.textContent}>
              {navTextArr[currentIndex]}
            </div>
          </div>
        </a>
        <div className={classes.dotGroup}>
          {linkArr.map((x, id) => {
            return (
              <div onClick={() => handleDotClick(id)}
                style={currentIndex == id ? background1ColorStyle : selesctDotColorStyle} className={classes.dot}></div>
            )
          })}
        </div>
      </div>

      <div ref={trackAppRef} className={classes.appContainer}>
        <div className={classes.appMainTitle}>
          {topAppTitle[state.languageIndex]}
        </div>
        <div className={classes.appGroup}>
          {appCollection
            .map((x, index) => {
              return (
                <FadeInSection>
                  <div className={classes.appGroup1}>
                    <div>
                      <div className={classes.appLogoImgContainer}>
                        <img
                          className={classes.appLogo}
                          src={appLogo[index]}
                        ></img>
                      </div>
                      <span className={classes.appTitle}>
                        {x[state.languageIndex]}
                      </span>
                      <ul className={classes.appDescription}>
                        {mainTextArr[index][state.languageIndex].map((y, id) => {
                          return <li className={classes.applist}>{y}</li>;
                        })}
                      </ul>
                      <div className={classes.buttonContainer}>

                        {/* {isAndroid&& */}
                        {index !== 4 ? <button className={classes.button} onClick={() => {
                          if (x[2])
                            window.location.href = x[2]
                        }}>
                          {buttonName[state.languageIndex]}
                        </button> : <button className={classes.button1} >Coming soon</button>}
                        {/* } */}
                        {x[3] ? (index !== 0 ?
                          (index !== 4 ? <a href={x[3]}>
                            <img
                              height={75}
                              alt="Get it on Google Play"
                              src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                            />
                          </a> : null) :
                          <a href={x[3]}>
                            <img
                              height={75}
                              alt="Get it on github"
                              src={githublogo}
                            />
                          </a>
                        ) : null}
                      </div>
                    </div>
                    <div className={classes.scrollPhone}>
                      <ScrollPhone
                        images={appImgArr[index]}
                        imgH={window.innerWidth > 700 ? 500 : 400}
                      />
                    </div>
                  </div>
                </FadeInSection>
              );
            })}
        </div>
      </div>
    </div>
  );
};
export default Home;
